import { saveTaskImage } from 'api/brief/fetchers/chats/saveTaskImage'
import { createUseMutation } from 'api/common/createUseMutation'
import { queryClient } from 'app/Root'

export const useSaveTaskImage = createUseMutation({
  fetcher: saveTaskImage,
  onMutate: async (newData: any) => {
    // Snapshot the previous value
    const previousData = queryClient.getQueryData(['task_image'])

    queryClient.setQueryData(['task_image'], (oldData: any) => ({
      ...(oldData || {}),
      ...newData,
    }))
    return { previousData }
  },
  onError: (err, newData, context) => {
    queryClient.setQueryData(['task_image'], (context as any).previousData)
  },
  onSettled: () => {
    queryClient.invalidateQueries(['task_image'])
  },
})
