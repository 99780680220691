import { assistantApi } from 'api'
import { MessagesWithGatheredData } from 'types/chats/chat'

export interface GetMessagesProps {
  page: number
  itemsPerPage: number
  role?: string
  type?: string
  chatId: string
  taskId: string
}

export const getMessages = ({ taskId, itemsPerPage, role, chatId, page, type }: GetMessagesProps) =>
  assistantApi.get<MessagesWithGatheredData>('/messages', {
    params: { chatId, taskId, role, type, page, itemsPerPage },
  })
