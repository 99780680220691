import {
  WppActionButton,
  WppIconAttach,
  WppIconImage,
  WppIconMicOn,
  WppIconSend,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useEffect, useState } from 'react'

import { Flex } from 'components/common/flex/Flex'

import styles from './InputActions.module.scss'

const QUESTION_MAX_LENGTH = 2000

interface InputActionsProps {
  question: string
  questionCharCount: number
  answerIsLoading: boolean
  onSubmit: () => void
}

const questionMaxLength = QUESTION_MAX_LENGTH

const attachButtonAction = () => {
  // console.log('Attach button clicked')
}

const imageButtonAction = () => {
  // console.log('Image button clicked')
}

const micButtonAction = () => {
  // console.log('Mic button clicked')
}

const InputActions = ({ question, questionCharCount, answerIsLoading, onSubmit }: InputActionsProps) => {
  const [sendButtonColor, setSendButtonColor] = useState('var(--wpp-grey-color-600)')

  useEffect(() => {
    const color =
      questionCharCount > 0 && questionCharCount <= questionMaxLength ? '#0014CC' : 'var(--wpp-grey-color-600)'
    setSendButtonColor(color)
  }, [questionCharCount])

  return (
    <Flex justify="between" align="center" className={styles.inputActions}>
      <Flex align="center">
        <WppActionButton disabled onClick={attachButtonAction}>
          <WppIconAttach />
        </WppActionButton>
        <WppActionButton disabled onClick={imageButtonAction}>
          <WppIconImage />
        </WppActionButton>
        <WppActionButton disabled onClick={micButtonAction}>
          <WppIconMicOn />
        </WppActionButton>
      </Flex>
      <Flex align="center">
        <WppTypography
          type="xs-body"
          className={clsx(
            question.length > questionMaxLength
              ? styles.questionCharacterLimitLabelError
              : styles.questionCharacterLimitLabel,
          )}
        >
          &nbsp;{questionCharCount}/{questionMaxLength}
        </WppTypography>
        <WppActionButton
          type="button"
          onClick={() => onSubmit()}
          className={clsx(styles.submitQuestionButton, 'cancel-drag')}
          disabled={answerIsLoading || questionCharCount === 0 || questionCharCount > questionMaxLength}
        >
          <WppIconSend size="m" color={sendButtonColor} />
        </WppActionButton>
      </Flex>
    </Flex>
  )
}

export default InputActions
