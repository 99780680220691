import { generateImageChat } from 'api/brief/fetchers/chats/generateImageChat'
import { createUseMutation } from 'api/common/createUseMutation'
import { queryClient } from 'app/Root'

export const useGenerateImage = createUseMutation({
  fetcher: generateImageChat,
  onMutate: async (newData: any) => {
    // Snapshot the previous value
    const previousData = queryClient.getQueryData(['messages'])

    queryClient.setQueryData(['messages'], (oldData: any) => ({
      ...(oldData || {}),
      ...newData,
    }))
    return { previousData }
  },
  onError: (err, newData, context) => {
    queryClient.setQueryData(['messages'], (context as any).previousData)
  },
  onSettled: () => {
    queryClient.invalidateQueries(['messages'])
  },
})
