import {
  WppButton,
  WppTypography,
  WppIconClose,
  WppActionButton,
  WppSkeleton,
} from '@platform-ui-kit/components-library-react'
import { useState } from 'react'

import { useGetTaskImage } from 'api/brief/queries/chats/useGetTaskImage'
import { Flex } from 'components/common/flex/Flex'
import { useAssistant } from 'hooks/useAssistant'
import { useChatContext } from 'hooks/useChatContext'

import styles from './Brief.module.scss'

interface ImageChoiceModalProps {
  isOpen: boolean
  onClose: () => void
  onImageSelect: (imageId: string) => void // Assuming imageId is a string for identification
  image1Src: string
  image2Src: string
}

const ImageChoiceModal: React.FC<ImageChoiceModalProps> = ({
  isOpen,
  onClose,
  onImageSelect,
  image1Src,
  image2Src,
}) => {
  const [image1Loaded, setImage1Loaded] = useState(false)
  const [image2Loaded, setImage2Loaded] = useState(false)

  if (!isOpen) {
    return null
  }

  const handleImageSelect = (imageId: string) => {
    onImageSelect(imageId)
    onClose()
  }

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: isOpen ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: isOpen ? 1 : 0,
        transition: 'opacity 300ms ease-in-out',
        zIndex: 1000,
      }}
    >
      <div
        style={{
          background: 'white',
          padding: 20,
          transform: isOpen ? 'translateY(0)' : 'translateY(-20vh)',
          transition: 'transform 300ms ease-in-out, opacity 300ms ease-in-out',
          opacity: isOpen ? 1 : 0,
          borderRadius: 10,
        }}
      >
        <Flex direction="row" justify="between" align="center" style={{ marginBottom: 8 }}>
          <WppTypography type="l-strong">Choose an image</WppTypography>
          <WppActionButton onClick={onClose}>
            <WppIconClose style={{ color: 'black' }} slot="icon-start" />
          </WppActionButton>
        </Flex>
        <img
          src={image1Src}
          alt="Choice 1"
          style={{
            cursor: 'pointer',
            marginRight: 10,
            width: 258,
            height: 260,
            objectFit: 'contain',
            borderRadius: 10,
            display: image1Loaded ? 'inline' : 'none',
          }}
          onLoad={() => setImage1Loaded(true)}
          onClick={() => handleImageSelect(image1Src)}
        />
        {!image1Loaded && (
          <WppSkeleton variant="rectangle" width={258} height={260} style={{ borderRadius: 10, marginRight: 10 }} />
        )}
        <img
          src={image2Src}
          alt="Choice 2"
          style={{
            display: image2Loaded ? 'inline' : 'none',
            cursor: 'pointer',
            width: 258,
            height: 260,
            objectFit: 'contain',
            borderRadius: 10,
          }}
          onLoad={() => setImage2Loaded(true)}
          onClick={() => handleImageSelect(image2Src)}
        />
        {!image2Loaded && <WppSkeleton variant="rectangle" width={258} height={260} style={{ borderRadius: 10 }} />}
      </div>
    </div>
  )
}

export function BriefImage({ onClick }: { onClick: () => void }) {
  const [modalOpen, setModalOpen] = useState(false)
  const [images, setImages] = useState<string[]>([])
  const chatContext = useChatContext()
  const { generateImage, isLoadingMutation, saveImage } = useAssistant({ activeTaskId: chatContext.taskId })
  const { data: taskImage, isLoading: isLoadingTaskImage } = useGetTaskImage({ params: { taskId: chatContext.taskId } })

  const handleGenerateImage = () => {
    onClick()
    generateImage({
      prompt: `generate images based on information from this structure: ${
        chatContext.messagesWithGatheredData.data?.gatheredData.targetAudience.description || 'brief building'
      }`,
    }).then(response => {
      setImages(response.images)
      setModalOpen(true)
    })
  }
  const handleImageSelect = (url: string) => {
    saveImage({ imageUrl: url })
  }
  if (!isLoadingTaskImage && taskImage?.[0]) {
    return null
  }
  return (
    <div>
      <WppButton
        className={styles.pillBlack}
        loading={isLoadingMutation}
        disabled={isLoadingMutation || isLoadingTaskImage || !!taskImage?.[0]}
        onClick={handleGenerateImage}
        // variant="secondary"
        type="button"
      >
        Generate Image
      </WppButton>
      <ImageChoiceModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onImageSelect={handleImageSelect}
        image1Src={images.length > 0 ? images[0] : ''}
        image2Src={images.length === 2 ? images[1] : ''}
      />
    </div>
  )
}
