// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NXqhO{display:flex}.lmeHE{display:inline-flex}.GepqD{flex-direction:column}.Df605{align-items:flex-start}.CAsiu{align-items:center}.Mu9Wp{align-items:flex-end}.REKNW{justify-content:start}.AsJJe{justify-content:center}.rBrQk{justify-content:flex-end}.lKGrX{justify-content:space-between}.pC5M0{justify-content:space-around}.MRhSv{flex-wrap:nowrap}.vUNkU{flex-wrap:wrap}`, "",{"version":3,"sources":["webpack://./src/components/common/flex/Flex.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CAGF,OACE,mBAAA,CAGF,OACE,qBAAA,CAGF,OACE,sBAAA,CAGF,OACE,kBAAA,CAGF,OACE,oBAAA,CAGF,OACE,qBAAA,CAGF,OACE,sBAAA,CAGF,OACE,wBAAA,CAGF,OACE,6BAAA,CAGF,OACE,4BAAA,CAGF,OACE,gBAAA,CAGF,OACE,cAAA","sourcesContent":[".root {\n  display: flex;\n}\n\n.inline {\n  display: inline-flex;\n}\n\n.column {\n  flex-direction: column;\n}\n\n.align-start {\n  align-items: flex-start;\n}\n\n.align-center {\n  align-items: center;\n}\n\n.align-end {\n  align-items: flex-end;\n}\n\n.justify-start {\n  justify-content: start;\n}\n\n.justify-center {\n  justify-content: center;\n}\n\n.justify-end {\n  justify-content: flex-end;\n}\n\n.justify-between {\n  justify-content: space-between;\n}\n\n.justify-around {\n  justify-content: space-around;\n}\n\n.nowrap {\n  flex-wrap: nowrap;\n}\n\n.wrap {\n  flex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `NXqhO`,
	"inline": `lmeHE`,
	"column": `GepqD`,
	"align-start": `Df605`,
	"align-center": `CAsiu`,
	"align-end": `Mu9Wp`,
	"justify-start": `REKNW`,
	"justify-center": `AsJJe`,
	"justify-end": `rBrQk`,
	"justify-between": `lKGrX`,
	"justify-around": `pC5M0`,
	"nowrap": `MRhSv`,
	"wrap": `vUNkU`
};
export default ___CSS_LOADER_EXPORT___;
