import { WppTypography } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'

import BriefContainer from 'components/brief/BriefContainer'
import { useHasPermissions } from 'hooks/useHasPermissions'

import styles from './App.module.scss'

export function App() {
  const { hasAccessToChatAssistant } = useHasPermissions()
  const { osContext } = useOs()

  if (!hasAccessToChatAssistant) {
    return null
  }

  const isOverlay = !osContext.project?.itemId

  return (
    <div className={isOverlay ? styles.briefContainer : styles.briefContainerNoOverlay}>
      <WppTypography type="2xl-heading">Marketing Opportunity Brief</WppTypography>
      <BriefContainer />
    </div>
  )
}
