import { createApi } from 'api/common/createApi'
import config, { API_TYPES } from 'constants/app-config'

export const assistantApi = createApi({
  baseURL: config[API_TYPES.AI_TECH_BRIEF_API],
})

export const assistantOpenPaApi = createApi({
  baseURL: config[API_TYPES.ASSISTANT_API],
})

export const workfrontApi = createApi({
  baseURL: config[API_TYPES.COLLIDER_API],
  headers: {
    'X-Workfront-Authorization ': `Bearer ${localStorage.getItem('WPP_WORKFRONT_TOKEN')}`,
    'Content-Type': 'multipart/form-data',
  },
})
