import { WppCard, WppSpinner, WppTypography } from '@platform-ui-kit/components-library-react'
import { MutableRefObject } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { useChatContext } from 'hooks/useChatContext'
import { Message } from 'types/chats/chat'

import { BriefImage } from './BriefImage'
import { MarkdownText } from './markdownText/MarkdownText'

function MessageCmp({ message }: { message: Message }) {
  if (message.role === 'user' && message.type === 'PROMPT') {
    return (
      <div style={{ display: 'flex', width: '100%' }}>
        <WppTypography
          tag="p"
          type="m-body"
          style={{
            maxWidth: '70%',
            display: 'inline-flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginLeft: 'auto',
            backgroundColor: '#EBEDFE',
            borderRadius: '8px',
            padding: '12px 16px 12px 16px',
          }}
        >
          <MarkdownText text={message.content ?? ''} />
        </WppTypography>
      </div>
    )
  }
  return (
    <WppCard variant="secondary" size="l">
      <WppTypography type="m-body" tag="p">
        <MarkdownText text={message.content ?? ''} />
      </WppTypography>
    </WppCard>
  )
}

export function BriefChatMessages({ question, refBubbles }: { question: string; refBubbles: MutableRefObject<any> }) {
  const { messagesWithGatheredData, isLoadingMessages } = useChatContext()

  return (
    <>
      {isLoadingMessages && (
        <Flex style={{ width: '100%' }} justify="center" align="center">
          <WppSpinner size="m" />
        </Flex>
      )}
      {messagesWithGatheredData.messages
        .filter(m => m.type !== 'PROMPT' || m.role !== 'system')
        .map(message => (
          <div key={message.id} ref={(refBubbles.current[message.id] ??= { current: null })}>
            <MessageCmp message={message} />
          </div>
        ))}
      {question && (
        <div style={{ display: 'flex', width: '100%' }}>
          <WppTypography
            tag="p"
            type="m-body"
            style={{
              maxWidth: '70%',
              display: 'inline-flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginLeft: 'auto',
              backgroundColor: '#EBEDFE',
              borderRadius: '8px',
              padding: '12px 16px 12px 16px',
            }}
          >
            <MarkdownText text={question} />
          </WppTypography>
        </div>
      )}
      {messagesWithGatheredData.data?.requirementsFulfilled && !question && <BriefImage onClick={() => {}} />}
    </>
  )
}
