import { useOs } from '@wpp-open/react'
import { isAxiosError } from 'axios'
import { useCallback, useMemo } from 'react'

import { useCreateChat } from 'api/brief/mutations/chats/useCreateChat'
import { useGenerateImage } from 'api/brief/mutations/chats/useGenerateImageChat'
import { useSaveTaskImage } from 'api/brief/mutations/chats/useSaveTaskImage'
import { useSendMessageChat } from 'api/brief/mutations/chats/useSendMessageChat'
import { useSaveBrief } from 'api/brief/mutations/workfront/useSaveBrief'

interface Props {
  activeTaskId?: string
  activeChatId?: string
}

let isLoadingMutation = false

async function convertImageToBinaryString(url: string, taskId: string, data: object) {
  try {
    const response = await fetch(url)
    const blob = await response.blob()
    const formData = new FormData()

    const blobText = new Blob([JSON.stringify(data)])
    formData.append('file_image', blob, `${taskId}.png`)
    formData.append('file_text', blobText, `${taskId}.txt`)
    return formData
  } catch (error) {
    console.error('Error converting image to binary string:', error)
    throw error // Rethrow or handle error as appropriate
  }
}

export const useAssistant = ({ activeTaskId = '', activeChatId = '' }: Props = {}) => {
  const { osContext } = useOs()
  const tenantId = osContext.tenant.id
  const { id: userId } = osContext.userDetails

  const { mutateAsync: createChat } = useCreateChat()
  const { mutateAsync: sendMessageChat } = useSendMessageChat()
  const { mutateAsync: generateImageApi } = useGenerateImage()
  const { mutateAsync: saveTaskImage } = useSaveTaskImage()
  const { mutateAsync: saveBriefWorkfront } = useSaveBrief()

  const startConversation = useCallback(
    async ({ taskId }: { taskId: string }): Promise<any | number | undefined> => {
      try {
        isLoadingMutation = true
        const response = await createChat({
          tenantId,
          userId,
          taskId,
        })
        return response.data
      } catch (err) {
        if (isAxiosError(err)) {
          return err.response?.status
        }
        console.error(err)
      } finally {
        isLoadingMutation = false
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createChat],
  )

  const sendMessage = useCallback(
    async ({ message }: { message: string }): Promise<any | number | undefined> => {
      try {
        isLoadingMutation = true
        const response = await sendMessageChat({
          role: 'user',
          type: 'OPEN_API',
          content: message,
          chatId: activeChatId,
          taskId: activeTaskId,
        })
        return response.data
      } catch (err) {
        if (isAxiosError(err)) {
          return err.response?.status
        }
        console.error(err)
      } finally {
        isLoadingMutation = false
      }
    },
    [sendMessageChat, activeChatId, activeTaskId],
  )

  const generateImage = useCallback(
    async ({ prompt }: { prompt: string }): Promise<any | number | undefined> => {
      try {
        isLoadingMutation = true
        const response = await generateImageApi({ prompt })
        return response.data
      } catch (err) {
        if (isAxiosError(err)) {
          return err.response?.status
        }
        console.error(err)
      } finally {
        isLoadingMutation = false
      }
    },
    [generateImageApi],
  )

  const saveImage = useCallback(
    async ({ imageUrl }: { imageUrl: string }): Promise<any | number | undefined> => {
      try {
        isLoadingMutation = true
        const response = await saveTaskImage({ imageUrl, taskId: activeTaskId })
        return response.data
      } catch (err) {
        if (isAxiosError(err)) {
          return err.response?.status
        }
        console.error(err)
      } finally {
        isLoadingMutation = false
      }
    },
    [activeTaskId, saveTaskImage],
  )

  const saveBrief = useCallback(
    async ({ file, data }: { file: string; data: object }): Promise<any | number | undefined> => {
      try {
        isLoadingMutation = true
        const response = await saveBriefWorkfront({
          brief: await convertImageToBinaryString(file, activeTaskId, data),
          taskId: activeTaskId,
        })
        return response.data
      } catch (err) {
        if (isAxiosError(err)) {
          return err.response?.status
        }
        console.error(err)
      } finally {
        isLoadingMutation = false
      }
    },
    [activeTaskId, saveBriefWorkfront],
  )

  return useMemo(
    () => ({
      startConversation,
      sendMessage,
      isLoadingMutation,
      generateImage,
      saveImage,
      saveBrief,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [startConversation, sendMessage, isLoadingMutation, generateImage, saveBrief, saveImage],
  )
}
