import { getMessages } from 'api/brief/fetchers/chats/getMessagesChat'
import { createUseQuery } from 'api/common/createUseQuery'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { Message, MessagesWithGatheredData } from 'types/chats/chat'

export const useGetMessages = createUseQuery({
  queryKey: ApiQueryKeys.MESSAGES,
  fetcher: getMessages,
  selector: res => res?.data ?? ({ messages: [] as Message[], data: {} } as MessagesWithGatheredData),
})
