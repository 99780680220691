import { createContext } from 'react'

import { ChatWithGatheredData, MessagesWithGatheredData } from 'types/chats/chat'

export interface ChatContextType {
  isLoadingChats: boolean
  chatsWithGatheredData: ChatWithGatheredData[]
  isLoadingMessages: boolean
  messagesWithGatheredData: MessagesWithGatheredData
  chatId: string
  taskId: string
}

export const ChatContext = createContext<ChatContextType>({} as ChatContextType)
export const ChatProvider = ChatContext.Provider
