import { getChats } from 'api/brief/fetchers/chats/getChats'
import { createUseQuery } from 'api/common/createUseQuery'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { ChatWithGatheredData } from 'types/chats/chat'

export const useGetChats = createUseQuery({
  queryKey: ApiQueryKeys.CHATS,
  fetcher: getChats,
  selector: res => res?.data ?? ([] as ChatWithGatheredData[]),
})
