import { PermissionsRecord } from '@wpp-open/core'

import { MayBeNull } from 'types/utils'

export const hasPermission = (
  permissions: MayBeNull<PermissionsRecord[]>,
  permission: string,
  accountId?: string,
): boolean => {
  const foundPermission = permissions?.find(({ account_id }) => account_id === accountId)

  return !!foundPermission?.permissions?.includes?.(permission)
}
