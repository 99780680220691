import { assistantApi } from 'api'
import { CreateChatVarsType } from 'types/chats/chat'

export const createChat = ({
  tenantId,
  taskId,
  userId,
  tenantUrl,
  pinned = false,
  messages = [],
}: CreateChatVarsType) => {
  return assistantApi.post('/chats', { tenantId, taskId, userId, tenantUrl, pinned, messages })
}
