import { getTaskImage } from 'api/brief/fetchers/chats/getTaskImage'
import { createUseQuery } from 'api/common/createUseQuery'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useGetTaskImage = createUseQuery({
  queryKey: ApiQueryKeys.TASK_IMAGE,
  fetcher: getTaskImage,
  selector: res =>
    res?.data?.data ??
    ([] as {
      id: string
      taskId: string
      url: string
    }[]),
})
