// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vSizB{height:42px;width:100%;padding:4px 4px 6px 4px}.ZmNVy{color:var(--wpp-danger-color-500)}.YQLRX{color:var(--wpp-grey-color-700)}`, "",{"version":3,"sources":["webpack://./src/components/brief/inputActions/InputActions.module.scss"],"names":[],"mappings":"AAAA,OACE,WAAA,CACA,UAAA,CACA,uBAAA,CAGF,OACE,iCAAA,CAGF,OACE,+BAAA","sourcesContent":[".inputActions {\n  height: 42px;\n  width: 100%;\n  padding: 4px 4px 6px 4px;\n}\n\n.questionCharacterLimitLabelError {\n  color: var(--wpp-danger-color-500);\n}\n\n.questionCharacterLimitLabel {\n  color: var(--wpp-grey-color-700);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputActions": `vSizB`,
	"questionCharacterLimitLabelError": `ZmNVy`,
	"questionCharacterLimitLabel": `YQLRX`
};
export default ___CSS_LOADER_EXPORT___;
