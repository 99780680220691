import { useMutation, UseMutationOptions, MutationFunction } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { QueryFetcher, QueryFetcherResponse, QueryFetcherParams } from 'api/common/types'

export function createUseMutation<F extends QueryFetcher, TVariables, TContext>({
  fetcher,
  onMutate,
  onError,
  onSettled,
}: {
  fetcher: F
  onMutate?: (variables: TVariables) => TContext | Promise<TContext>
  onError?: (error: any, variables: TVariables, context: TContext | undefined) => void | Promise<void>
  onSettled?: (
    data: any | undefined,
    error: any | null,
    variables: TVariables,
    context: TContext | undefined,
  ) => void | Promise<void>
}) {
  return function useCustomMutation(
    options?: UseMutationOptions<QueryFetcherResponse<F>, AxiosError, QueryFetcherParams<F>>,
  ) {
    return useMutation(
      (params => fetcher(params)()) as MutationFunction<QueryFetcherResponse<F>, QueryFetcherParams<F>>,
      {
        ...options,
        onMutate,
        onError,
        onSettled,
      },
    )
  }
}
