import PreviewNoContentImage from 'assets/images/preview-no-content.svg'

import styles from './Brief.module.scss'

export default function PreviewNoContent() {
  return (
    <div className={styles.noContentImage}>
      <img src={PreviewNoContentImage} alt="No content" />
    </div>
  )
}
