import { WppCard, WppPill, WppTypography } from '@platform-ui-kit/components-library-react'

import styles from '../Brief.module.scss'

export default function WelcomeBriefAssistant(props: { onStartConversation: () => void }) {
  return (
    <>
      <WppCard variant="secondary" size="l">
        <div slot="header">
          <WppTypography type="l-strong">Welcome to the Brief Assistant!</WppTypography>
        </div>
      </WppCard>

      <WppCard variant="secondary" size="l">
        <WppTypography tag="p">
          Hello there! I'm here to guide you through creating a detailed technical brief for your marketing campaign.
          Whether you're looking to boost brand awareness, drive sales, or launch a new product, I'll help you compile
          all the essential details into a comprehensive brief.
        </WppTypography>
        <div style={{ marginTop: 20 }} />
        <WppTypography tag="p">
          To get started, I'll ask you a series of questions covering your campaign's goals, target audience, budget,
          and more. Your answers will help us craft a tailored brief that aligns perfectly with your marketing
          objectives. Don't worry; you can edit your responses at any time before finalizing.
        </WppTypography>
        <div style={{ marginTop: 20 }} />
        <WppTypography tag="p">If you're ready, let's dive in and make your campaign vision a reality!</WppTypography>
      </WppCard>
      <WppPill
        className={styles.pillBlack}
        onClick={props.onStartConversation}
        removable={false}
        label="Let’s get started"
        type="display"
      />
    </>
  )
}
