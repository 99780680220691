import ReactMarkdown from 'react-markdown'

import styles from './MarkdownText.module.scss'

interface Props {
  text: string
}

export const MarkdownText = ({ text }: Props) => {
  return <ReactMarkdown className={styles.markdownText}>{text}</ReactMarkdown>
}
